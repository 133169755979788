/**
 * Util logic used for all components.
 * Be very careful adding new things here as this will impact every component bundle!
 */
import { createLogic } from "redux-logic";
import * as DOMUtils from "history/DOMUtils";
import type { SetFinanceOptionType } from "../actions/UtilActions";
import { SET_FINANCE_OPTION } from "../actions/UtilActions";
import { updateFinanceQueryParam } from "../../History";
import type { CommonLogicType } from "../typeHelpers";

const setFinanceOption = (createLogic as CommonLogicType<SetFinanceOptionType>)({
    type: SET_FINANCE_OPTION,

    process({ action }, dispatch, done) {
        if (DOMUtils.canUseDOM) {
            if (!action.noHistoryPush) updateFinanceQueryParam(action.option);
        }
        done();
    },
});

export default [setFinanceOption];
