import type { SyntheticEvent } from "react";
import { getCompareUSCLabel } from "./labels";
import type { CompareUscSettingsType } from "../redux/reducers/CompareUscSettingsReducer";
import type { CommonSettingsType } from "../../../common-deprecated/settings/fetchCommonSettings";
import type { CompareUSCDispatchType } from "../redux/store";
import type { UsedCompareCarType } from "../../../common-deprecated/features/compare/utils/usedCarCompareTypes";
import { getUscVehicleUrlInfoFromCompareResult } from "./usedSpecsUtils";
import { getUscUrl } from "../../shared-logic/utils/uscUtils";
import { showModal } from "../../../common-deprecated/redux/actions/ModalActions";
import type { DealerResultType } from "../../../common-deprecated/utils/dealerConstants";
import { getUscCashPriceFromVehicle } from "../../shared-logic/utils/uscPriceUtils";
import { MODAL_USC_FORM } from "../../shared-logic/utils/integrated-modal/integratedModalConstants";
import { DfVehicleStatus } from "../../shared-logic/utils/dfConstants";

/**
 * Opens the Contact Dealer form
 */
export const openContactDealerForm = (
    vehicle: UsedCompareCarType,
    detailPageUrl: string,
    context: "used" | "stock",
    commonSettings: CommonSettingsType,
    dispatch: CompareUSCDispatchType,
    modalTitle: string,
): void => {
    const { loading } = vehicle;

    if (loading) return;

    const urlInfo = getUscVehicleUrlInfoFromCompareResult(vehicle);
    const vehicleUrl = getUscUrl(commonSettings, detailPageUrl, "used", urlInfo);

    // modalLogic will attach additional vehicle/dealer/finance/... information to this event so we dont have to propagate it.
    dispatch(
        showModal({
            type: MODAL_USC_FORM,
            vehicleForSaleId: vehicle.id,
            uscContext: context,
            modalTitle,
            externalData: {
                externalPriceInfo: {
                    financeInfo: vehicle.financeInfo,
                    cashPrice: getUscCashPriceFromVehicle(vehicle),
                    monthlyPrice: vehicle.monthlyPrice,
                },
                vehicleUrl,
            },
        }),
    );
};

/**
 * Returns a Contact Dealer CTA to be used on the Car Compare
 * This version returns a more limited object compared to the used-stock-cars getContactDealerCta containing only the label of the
 * button & the onClick event. This is mainly due to not needing the other data
 * at this time
 */
export const getContactDealerCta = (
    vehicle: UsedCompareCarType,
    detailPageUrl: string,
    commonSettings: CommonSettingsType,
    compareUscSettings: CompareUscSettingsType,
    dealer: DealerResultType | null,
    dispatch: CompareUSCDispatchType,
    tracking: () => void,
    localContactDealerClick?: () => void,
): {
    click: ((event: SyntheticEvent) => void) | null;
    label: string;
} | null => {
    // Don't show the CTA if the vehicle is sold or there is no dealer,
    // as the user should not be able to contact the dealer
    if ((!vehicle.loading && vehicle.vehicleStatus.code === DfVehicleStatus.Sold) || !dealer) return null;

    const { localContactDealerFormUrl } = compareUscSettings;

    const label = getCompareUSCLabel({ compareUscSettings, commonSettings }, "usedCompareButtonAppointment");

    let contactDealerclick: ((event: SyntheticEvent) => void) | null = null;

    if (localContactDealerFormUrl && !commonSettings.query.disableLocalContactForm && !!localContactDealerClick) {
        contactDealerclick = localContactDealerClick;
    } else if (vehicle) {
        const modalTitle = getCompareUSCLabel(
            { compareUscSettings, commonSettings },
            "usedCompareContactDealerModalTitle",
        );
        contactDealerclick = () => {
            tracking();
            openContactDealerForm(vehicle, detailPageUrl, "used", commonSettings, dispatch, modalTitle);
        };
    }

    return {
        click: contactDealerclick,
        label,
    };
};
