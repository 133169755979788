import { getStorageItem, setStorageItem, WebStorageType } from "../../../common-deprecated/utils/storageUtils";
import type { LoadingUsedCompareCarType } from "../../../common-deprecated/features/compare/utils/usedCarCompareTypes";

// ----------------------------------------------------------------------
// Helper Types and consts
// ----------------------------------------------------------------------

const LOCAL_STORAGE_ID = "onlineRetail.compare.usedCarData" as const;

type CompareLocalStorageItemType = { vehicles: (Omit<LoadingUsedCompareCarType, "loading"> & { loaded?: boolean })[] };

// Type used in the functions for easy param propagation.
type CompareLocalStorageParams = {
    country: string;
    language: string;
};

// ----------------------------------------------------------------------
// Storage fns
// ----------------------------------------------------------------------

const getCompareLocalStorage = (params: CompareLocalStorageParams): CompareLocalStorageItemType | null => {
    return (
        getStorageItem<CompareLocalStorageItemType>({ ...params, storageId: LOCAL_STORAGE_ID }, WebStorageType.local) ||
        null
    );
};
/**
 * Set compare local storage, only overwriting the values supplied in the storageItem param.
 */
const setCompareLocalStorage = (params: CompareLocalStorageParams, storageItem: CompareLocalStorageItemType): void => {
    setStorageItem({ ...params, storageId: LOCAL_STORAGE_ID }, storageItem, WebStorageType.local);
};

// ----------------------------------------------------------------------
// Helper fns
// ----------------------------------------------------------------------

export const saveUsedCarsToLocalStorage = (
    usedCars: Omit<LoadingUsedCompareCarType, "loading">[],
    params: CompareLocalStorageParams,
): void => {
    const cleanedCars = usedCars.map((car) => ({ ...car, loaded: undefined }));
    setCompareLocalStorage(params, { vehicles: cleanedCars });
};

export const getUsedCarsFromLocalStorage = (
    params: CompareLocalStorageParams,
): CompareLocalStorageItemType["vehicles"] => {
    return getCompareLocalStorage(params)?.vehicles || [];
};
