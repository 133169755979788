import Debug from "../Debug";
import type { CommonSettingsType } from "../settings/fetchCommonSettings";

export const handleTabError = (
    message: string,
    error: Error | string | null,
    commonSettings: CommonSettingsType,
    tab: Window | null,
): void => {
    Debug.error(`Tab Error - redirect tab to 404 page: ${message}`, error || "");
    if (tab) tab.location.href = `${commonSettings.urlWebsite}/404`;
};

export const getAndOpenNewTab = (): Window | null => {
    const tab = window.open();
    if (tab) tab.document.write(""); // This activates the loader in the browser so that the user gets feedback. Stop automatically when href is changed
    return tab;
};

/**
 * Opens a pdf based on the url in the provided tab
 * If no defaultTab was passed, a new tab will be opened (only works if printPdf is used right after a click)
 *
 *
 * If there are some calls/rerenders between the click and the opening of the new tab (For example fetching the tyCode)
 * you can use printPdf by opening a tab right after the click with (function: getAndOpenNewTab) and set it as defaultTab parameter
 * This is needed because on Safari the opening of the new tab will be blocked otherwise (OR-4037)
 *
 */
export const printPdf = async (
    url: string,
    commonSettings: CommonSettingsType,
    defaultTab?: Window | null,
): Promise<void> => {
    console.log("Opening PDF", url);
    if (commonSettings.environment === "development") {
        window.open(url);
    } else {
        const tab = defaultTab || getAndOpenNewTab();
        try {
            const result = await fetch(commonSettings.pdfService, {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({
                    url,
                    printBackground: true,
                    margin: {
                        top: "30px",
                        bottom: "30px",
                        right: "30px",
                        left: "30px",
                    },
                }),
            });
            if (result.ok) {
                const parsedResult = await result.blob();
                const fileURL = URL.createObjectURL(parsedResult);
                if (tab) tab.location.href = fileURL;
            } else {
                handleTabError("Could not open PDF", result.statusText, commonSettings, tab);
            }
        } catch (e) {
            handleTabError("Could not open PDF", e as Error, commonSettings, tab);
        }
    }
};
