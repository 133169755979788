import { useEffect, useRef } from "react";
import { getUsedCarIdsFromUrl } from "../utils/uscCompareUtils";
import { getUsedCarsFromLocalStorage, saveUsedCarsToLocalStorage } from "../utils/localStorage";
import { getPostal } from "../../../common-deprecated/utils/postalUtils";
import { POSTAL_CHANNEL_T1_COMPARE_V2, POSTAL_TOPIC_T1_COMPARE_V2_ADD_CAR } from "../../../common-deprecated/constants";
import Debug from "../../../common-deprecated/Debug";
import { usedCompareAddCars } from "../../shared-logic/redux/actions/UsedCarCompareExternalDataActions";
import type { CompareUSCDispatchType } from "../redux/store";
import { useCompareUSCSelector } from "../redux/store";
import type { PostalUsedDataType } from "../utils/compareTypes";
import { CompareV2Mode } from "../../../shared-logic/features/compare/utils/types";
import { useDispatch } from "../../../shared-logic/redux/store";

const useInitUsedCompare = (): void => {
    const settings = useCompareUSCSelector(({ commonSettings }) => commonSettings);
    const dispatch = useDispatch<CompareUSCDispatchType>();

    const postalSubscription = useRef<ReturnType<Postal["subscribe"]> | null>();
    useEffect(() => {
        if (settings.query.compareData) {
            const cars = getUsedCarIdsFromUrl(settings.query.compareData);
            if (cars.length) dispatch(usedCompareAddCars(cars, 0));
        }

        postalSubscription.current = getPostal().subscribe({
            channel: POSTAL_CHANNEL_T1_COMPARE_V2,
            topic: POSTAL_TOPIC_T1_COMPARE_V2_ADD_CAR,
            callback: (data: PostalUsedDataType) => {
                if (data.mode === CompareV2Mode.Used && data.id) {
                    dispatch(
                        usedCompareAddCars(
                            [
                                {
                                    id: data.id,
                                    product: {
                                        brand: { description: data.brand || "", code: "" },
                                        model: data.model || "",
                                    },
                                },
                            ],
                            // We used to look at props.cars.length but this is run when component mounts, so it is always 0.
                            // By setting it to -1 we will check in the logic how many cars we have in the compare.
                            -1,
                        ),
                    );
                } else {
                    Debug.error(`Could not add used car to compare: ${JSON.stringify(data)}`);
                }
            },
        });

        const cars = getUsedCarsFromLocalStorage(settings);

        if (cars.length && !settings.query.compareData) {
            // Temporary check if every car has the new product formatting. If not clear the local storage of these cars
            if (cars.every((car) => car.product)) {
                dispatch(usedCompareAddCars(cars, 0));
            } else {
                saveUsedCarsToLocalStorage([], settings);
            }
        }

        return () => {
            if (postalSubscription.current) {
                // Unsubscribe from postal, we want to stop dispatches when switched away from mode
                getPostal().unsubscribe(postalSubscription.current);
                postalSubscription.current = null;
            }
        };
    }, []);
};

export default useInitUsedCompare;
