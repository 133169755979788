import { useContext, useState, useEffect } from "react";
import { ThemeContext } from "styled-components";
import { getMaxNumberOfCarsToCompare } from "../utils/helpers";
import type { StateType } from "../../../../common-deprecated/redux/typeHelpers";
import { useSelector } from "../../../redux/store";

// This hook can not be used on server side rendered components!! it uses the innerwidth of browser window.
const useMaxNumberToCompare = (): number => {
    const newCarLength = useSelector((state: StateType) => state.newCarCompare?.grades?.length);
    const usedCarLength = useSelector((state: StateType) => state.usedCarCompareExternalData?.cars?.length);

    const { breakpoints } = useContext(ThemeContext);

    // Used cars.length here because the useEffect hook will not work in the compare PDF due to reactDOM.renderToString.
    // Setting this will make it so the PDF will have the right amount of columns on PDF. To be sure we fallback to the max amount of cars possible.
    const [maxNumber, setMaxNumber] = useState(newCarLength || usedCarLength || 4);

    // Set the correct value on initial render
    useEffect(() => {
        setMaxNumber(getMaxNumberOfCarsToCompare(breakpoints));
    }, []);

    // Update value when resizing
    useEffect(() => {
        const updateMax = (): void => {
            const newMax = getMaxNumberOfCarsToCompare(breakpoints);
            if (newMax !== maxNumber) setMaxNumber(newMax);
        };
        window.addEventListener("resize", updateMax);
        return () => window.removeEventListener("resize", updateMax);
    }, [maxNumber]);

    return maxNumber;
};

export default useMaxNumberToCompare;
