import type { Store } from "redux";
import type { PostFetchFnType } from "../../../utils/registryTypes";
import type { UtilStateType } from "../../../redux/reducers/UtilReducer";
import type { CommonSettingsType } from "../../../settings/fetchCommonSettings";
import type { UtilActionsType } from "../../../redux/actions/UtilActions";
import { shouldShowErrorModal } from "../../../utils";
import { MODAL_ERRORS } from "../../../utils/modalConstants";
import type { ModalActionsType } from "../../../redux/actions/ModalActions";
import { showModal } from "../../../redux/actions/ModalActions";
import type { ModalReducerType } from "../../../redux/reducers/ModalReducer";

// Typings for a base common store. These reducers should be present on every render.
// Make sure to check if these actually exist in the store though.
export type CommonPostFetchState = {
    utils: UtilStateType;
    commonSettings: CommonSettingsType;
    modal: ModalReducerType;
};
export type CommonPostFetchStore = Store<CommonPostFetchState, UtilActionsType | ModalActionsType>;

/**
 * PostFetch function which is executed for *all* components.
 * Be careful with adding logic here!
 *
 * The addCacheHeaders return param is ignored here.
 */
export const commonPostFetch: PostFetchFnType<CommonPostFetchStore> = (store) => {
    const { dispatch, getState } = store;
    const { utils, commonSettings, modal } = getState();

    // Cache header flags are ignored for commonPostFetch.
    // Failsafe: if no utils or commonSettings are present dont execute this function.
    if (!utils || !commonSettings) return { addCacheHeaders: false };

    // Show errors if there are any. Make sure it isnt shown already (could already be triggered server side in a client side postFetch).
    if (
        shouldShowErrorModal(commonSettings, utils.errorLog) &&
        !modal.modals.find(({ settings }) => settings?.type === MODAL_ERRORS)
    ) {
        const modalSettings = {
            type: MODAL_ERRORS,
            errors: utils.errorLog,
            componentName: commonSettings.component,
        };
        dispatch(showModal(modalSettings));
    }

    return { addCacheHeaders: false };
};
