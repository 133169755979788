import type { ImgHTMLAttributes } from "react";
import type { CommonSettingsType } from "../fetchCommonSettings";
import { getModelToken } from "./commonSettingUtils";
import type { QueryType } from "../../server/types";
import Debug from "../../Debug";

export enum ImageMode {
    Exterior = "day-exterior",
    Interior = "interior",
    ClayExterior = "clay-mobility-exterior",
    ClayInterior = "clay-mobility-interior",
}

export type ImageCarType = {
    modelId: string;
    carId: string;
    gradeId?: string;
    bodyTypeId?: string;
};
export type ImageCarConfigType = {
    colorCode?: string;
    upholsteryCode?: string;
    packIds?: string[];
    accessoryIds?: string[];
    optionIds?: string[];
};

export type ImageOptionsType = {
    type?: "png" | "jpg" | "webp";
    bgColour?: string;
    bgImage?: string;
    scaleMode?: number | string;
    padding?: number | string;
    width?: number | string;
    height?: number | string;
    imageQuality?: number | string;
    srcSet?: Array<ImageSrcSet>;
    // set useCompressed to true to either resize or crop using the compressed CCIS service
    useCompressed?: boolean;
    resizeDimensions?: {
        width: number;
        height: number;
    };
    cropDimensions?: {
        width: number;
        height: number;
    };
    useAvailableInOtherGradeImages?: {
        overrideCarImageInput: ImageCarConfigType;
        overrideCarIds: ImageCarType;
        wheelImage?: string;
    };
};

export type ImageSrcSet = {
    width: number | string;
    height: number | string;
    padding?: number | string;
};

export const canUseCompressedCcisPath = <T extends { compressedCcisPath: string; query: QueryType }>(
    settings: T,
    wantToUseCompressed: boolean,
): boolean => {
    return (
        !!settings.compressedCcisPath && // if env variable is set
        settings.query.useCompressedCcis !== false && // force disable
        (wantToUseCompressed || settings.query.useCompressedCcis === true)
    ); // prop or force enable
};
export const getCcisPath = (settings: CommonSettingsType, imageOptions: ImageOptionsType = {}): string => {
    const { useCompressed = false, width: originalWidth, height: originalHeight } = imageOptions;
    const { cropDimensions, resizeDimensions } = imageOptions;

    const willUseCompressed = canUseCompressedCcisPath(settings, useCompressed);

    // resizing to the original dimensions is the fallback way to simply use a compressed version of the image
    const action = cropDimensions ? "crop" : "resize";
    const width = cropDimensions?.width || resizeDimensions?.width || originalWidth;
    const height = cropDimensions?.height || resizeDimensions?.height || originalHeight;

    // the width and height property here are the ones the image will be resized / cropped to
    // while the width & height in for example getCarImage is the source size
    return willUseCompressed
        ? `${settings.compressedCcisPath}/ccis/${action}/${width as string}x${height as string}/zip`
        : settings.ccisPath;
};

/**
 * Get the visual-for-grade image based on the bodyType/grade id.
 * Mostly used for gradepage-like images, images used in build and buy are usually more specific.
 * Pass a mode attribute if you wish to render interior images
 */
export const getVisualForGradeImage = (
    settings: CommonSettingsType,
    modelId: string,
    gradeId: string,
    bodyTypeId?: string,
    options: ImageOptionsType = {},
    frame: number | string = 9,
    colourCodes?: string,
    token?: string,
    mode: "exterior" | "day-interior" | "static-day-interior" = "exterior",
): Pick<ImgHTMLAttributes<HTMLImageElement>, "srcSet"> & Required<Pick<ImgHTMLAttributes<HTMLImageElement>, "src">> => {
    const isExterior = mode === "exterior";

    const {
        type = "jpg",
        bgImage = "",
        bgColour = isExterior ? "FFFFFF" : "",
        scaleMode = 1,
        width = 1140,
        height = 426,
        padding = isExterior ? undefined : 10,
        imageQuality = 75,
    } = options;

    const ccisPath = getCcisPath(settings, options);
    const modelToken = token || getModelToken(settings, modelId);
    const model = modelToken ? `/product-token/${modelToken}` : `/model/${modelId}`;

    // Use background image if one is available. Otherwise, use background color
    let backgroundUrl = "";
    if (bgImage) backgroundUrl = `/background-image/${bgImage}`;
    else if (bgColour) backgroundUrl = `/background-colour/${bgColour}`;

    const getFileURl = (srcSetValue?: ImageSrcSet): string => {
        return (
            `${ccisPath}/${settings.country}/configurationtype/visual-for-grade-selector${model}` +
            `/grade/${gradeId}${bodyTypeId ? `/body/${bodyTypeId}` : ""}/fallback/true/width/` +
            `${srcSetValue?.width ?? width}/height/${srcSetValue?.height ?? height}/scale-mode/${scaleMode}` +
            `${typeof padding !== "undefined" ? `/padding/${srcSetValue?.padding ?? padding}` : ""}` +
            `${backgroundUrl}/image-quality/${imageQuality}/${mode}-${frame}${colourCodes ? `_${colourCodes}` : ""}` +
            `.${type}${srcSetValue?.width && srcSetValue.height ? ` ${srcSetValue.width}w` : ""}`
        );
    };

    const srcSet = options?.srcSet?.map((srcSetValue) => getFileURl(srcSetValue)).join(", ");

    return { src: getFileURl(), srcSet };
};

/**
 * Get an image image based on the modelId / carId
 */
export const getCarImage = (
    settings: CommonSettingsType,
    mode: string,
    frame: number | string,
    modelId: string,
    carId: string,
    options: ImageOptionsType = {},
    configurationUri = "",
    colourCodes = "",
): string => {
    const {
        type = "jpg",
        bgColour = "",
        bgImage = "",
        scaleMode = 1,
        padding = 10,
        width = 1140,
        height = 426,
        imageQuality = 75,
    } = options;

    const token = getModelToken(settings, modelId);
    const model = token ? `/product-token/${token}/vehicle/${carId}` : `/vehicle/${modelId}/${carId}`;

    // Use background image if one is available. Otherwise, use background color
    let backgroundUrl = "";
    if (bgImage) backgroundUrl = `background-image/${bgImage}/`;
    else if (bgColour) backgroundUrl = `background-colour/${bgColour}/`;

    return (
        `${getCcisPath(settings, options)}/${settings.country}${model}${configurationUri}` +
        `/width/${width}/height/${height}/scale-mode/${scaleMode}/padding/${padding}/${backgroundUrl}image-quality/${imageQuality}` +
        `/${mode}-${frame}${colourCodes}.${type}`
    );
};

/**
    Get the visual for the model selector based on the modelId
    for now, this is only used in the model-filter

    IMPORTANT -> For the ccis images with scaleMode 3 an aspect ratio of 3:2 is required else the scaling logic at CCIS side does not work.
    Currently only the getCarImageVisualForModel utilizes this scaleMode.
    This does not take padding into account, so find the best dimensions only using width & height (respecting 3:2 obviously) and than adjust the padding to fine tune the image size.
 */
export const getCarImageVisualForModel = (
    settings: CommonSettingsType,
    mode: string,
    frame: number | string,
    modelId: string,
    options: ImageOptionsType = {},
): Pick<ImgHTMLAttributes<HTMLImageElement>, "srcSet"> & Required<Pick<ImgHTMLAttributes<HTMLImageElement>, "src">> => {
    const {
        type = "jpg",
        bgColour = "",
        scaleMode = 3,
        padding = 0,
        width = 300,
        height = 200,
        imageQuality = 75,
    } = options;

    const token = getModelToken(settings, modelId);
    const model = token ? `/product-token/${token}` : `/model/${modelId}`;
    const backgroundUrl = bgColour ? `background-colour/${bgColour}/` : "";

    // Make sure the width:height is 3:2 = 3/2 = 1.5
    if (Number(width) / Number(height) !== 1.5)
        Debug.error("Aspect ratio for the visual-for-model-selector should be 3:2");

    const getFileUrl = (srcSetValue?: ImageSrcSet): string => {
        return (
            `${getCcisPath(settings, options)}/${
                settings.country
            }/configurationtype/visual-for-model-selector${model}/fallback/true` +
            `/width/${srcSetValue?.width ?? width}/height/${
                srcSetValue?.height ?? height
            }/scale-mode/${scaleMode}/padding/${
                srcSetValue?.padding ?? padding
            }/${backgroundUrl}image-quality/${imageQuality}` +
            `/${mode}-${frame}.${type}`
        );
    };

    const srcSet = options?.srcSet?.map((srcSetValue) => getFileUrl(srcSetValue)).join(", ");

    return { src: getFileUrl(), srcSet };
};

/**
 * Get the visual for the Lexus grade selector, also requiring the submodel as criteria
 */
export const getSubmodelGradeImage = (
    settings: CommonSettingsType,
    frame: number | string,
    modelId: string,
    subModelId: string,
    gradeId?: string,
): string => {
    const width = 1140;
    const height = 426;
    const { country } = settings;

    const token = getModelToken(settings, modelId);
    const model = token ? `/product-token/${token}` : `/model/${modelId}/`;
    const grade = gradeId ? `/grade/${gradeId}` : "";

    return (
        `${getCcisPath(settings, { width, height })}/${country}${model}/submodel/${subModelId}${grade}` +
        `/width/${width}/height/${height}/background-colour/00FFFFFF/image-quality/75` +
        `/day-exterior-${frame}.png`
    );
};

export const get3dJsonObject = (
    settings: CommonSettingsType,
    modelId: string,
    carId: string,
    options: ImageOptionsType = {},
    configurationUri = "",
    colourCodes = "",
): string => {
    const token = getModelToken(settings, modelId);
    const model = token ? `/product-token/${token}/vehicle/${carId}` : `/vehicle/${modelId}/${carId}`;

    return (
        `${getCcisPath(settings, options)}/${settings.country}${model}${configurationUri}` +
        `/exterior${colourCodes}.json`
    );
};
