import type { CompareUscSettingsType } from "../reducers/CompareUscSettingsReducer";

export const COMPARE_USC_SETTINGS_INIT = "compareUscSettings/init";

type InitCompareUscSettingsType = {
    type: typeof COMPARE_USC_SETTINGS_INIT;
    initState: CompareUscSettingsType;
};

export const initCompareUscSettings = (initState: CompareUscSettingsType): InitCompareUscSettingsType => ({
    type: COMPARE_USC_SETTINGS_INIT,
    initState,
});

export type CompareUscSettingsActionsType = InitCompareUscSettingsType;
