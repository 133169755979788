import { COMPONENT_COMPARE_USC_PDF } from "../../../shared-logic/server/components";
import type { UsedCarCompareType } from "../../../common-deprecated/features/compare/utils/usedCarCompareTypes";
import { getUsedCarIdsFromUrl } from "../utils/uscCompareUtils";
import type { CompareUSCDispatchType, CompareUSCStateType } from "../redux/store";
import { usedCompareSetParsedCars } from "../../shared-logic/redux/actions/UsedCarCompareExternalDataActions";
import type { UscEnv } from "../../shared-logic/utils/uscUtils";
import type { CompareUscSettingsType } from "../redux/reducers/CompareUscSettingsReducer";
import { initCompareUscSettings } from "../redux/actions/CompareUscSettingsActions";
import type { FetchFnReturnType } from "../../../common-deprecated/utils/backendFetch";

// ----------------------------------------------------------------------
// Fetch types
// ----------------------------------------------------------------------
export const FETCH_COMPARE_USC_SETTINGS = "fetchCompareUscSettings" as const;
export const FETCH_COMPARE_USC_PDF = "fetchUsedComparePdf" as const;

export type FetchCompareUscSettingsType = {
    fetchType: typeof FETCH_COMPARE_USC_SETTINGS;
    result?: FetchFnReturnType<CompareUscSettingsType>;
};

export type FetchUsedCompareV2PdfType = {
    fetchType: typeof FETCH_COMPARE_USC_PDF;
    ids: string[];
    uscEnv: UscEnv;
    result?: UsedCarCompareType[];
};

export const fetchCompareUscSettings = (): FetchCompareUscSettingsType => ({
    fetchType: FETCH_COMPARE_USC_SETTINGS,
});

export const fetchCompareUsedCarData = (ids: string[], uscEnv: UscEnv): FetchUsedCompareV2PdfType => ({
    fetchType: FETCH_COMPARE_USC_PDF,
    ids,
    uscEnv,
});

export type CompareUSCFetchTypes = FetchCompareUscSettingsType | FetchUsedCompareV2PdfType;

// ----------------------------------------------------------------------
// Fetch getter
// ----------------------------------------------------------------------
export const getCompareUSCComponentFetches = <T extends CompareUSCStateType>(state: T): CompareUSCFetchTypes[] => {
    const fetches: CompareUSCFetchTypes[] = [];
    const { compareUscSettings, commonSettings, usedCarCompareExternalData } = state;

    if (!compareUscSettings.initialized) {
        fetches.push(fetchCompareUscSettings());
        return fetches;
    }

    if (!usedCarCompareExternalData.cars.length && commonSettings.component === COMPONENT_COMPARE_USC_PDF) {
        if (commonSettings.query.compareData) {
            const fetchData = getUsedCarIdsFromUrl(commonSettings.query.compareData as string);

            const ids = fetchData.map(({ id }) => id);

            fetches.push(fetchCompareUsedCarData(ids, compareUscSettings.uscEnv));
        }
    }

    return fetches;
};

// ----------------------------------------------------------------------
// Dispatcher
// ----------------------------------------------------------------------
export const compareUSCFetchDispatcher = (
    data: Required<CompareUSCFetchTypes>,
    dispatch: CompareUSCDispatchType,
): void => {
    if (data.fetchType === FETCH_COMPARE_USC_SETTINGS) {
        dispatch(initCompareUscSettings(data.result.fetchResult));
    }

    if (data.fetchType === FETCH_COMPARE_USC_PDF) {
        dispatch(usedCompareSetParsedCars(data.result, -1));
    }
};
