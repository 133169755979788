import type { CocaDapPriceType } from "../../../common-deprecated/features/compare/utils/newCarCompareTypes";
import {
    getAemPriceConfig,
    showAemPriceExclVAT,
} from "../../../common-deprecated/settings/utils/financeSettingUtilsAem";
import type { PriceConfigType } from "../../../common-deprecated/types/CommonTypes";
import { AemFmComponent } from "../../../common-deprecated/utils/aemFlexibilityMatrixUtils";
import { useCompareUSCSelector } from "../redux/store";

type useUsedEquipmentOrSpecPriceReturnType = {
    normalPrice?: number;
    discountedPrice?: number;
    priceConfig: PriceConfigType;
};

const useUsedEquipmentOrSpecPrice = (
    index: number,
    price?: CocaDapPriceType,
): useUsedEquipmentOrSpecPriceReturnType => {
    const showPriceExclVat = useCompareUSCSelector((state) =>
        showAemPriceExclVAT(state.commonSettings, AemFmComponent.Compare),
    );

    const priceConfig = useCompareUSCSelector((state) =>
        getAemPriceConfig(state.commonSettings, AemFmComponent.Compare),
    );

    const normalPrice = showPriceExclVat ? price?.net : price?.list;
    const discountedPrice = showPriceExclVat ? price?.netWithDiscount : price?.listWithDiscount;

    return {
        normalPrice,
        discountedPrice,
        priceConfig,
    };
};

export default useUsedEquipmentOrSpecPrice;
