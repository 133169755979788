import { showModal } from "../../../common-deprecated/redux/actions/ModalActions";
import { usedCompareClearAll } from "../../shared-logic/redux/actions/UsedCarCompareExternalDataActions";
import { MODAL_DELETE_CAR } from "../../../shared-logic/features/compare/utils/modalConstants";
import type { CompareUSCDispatchType } from "../redux/store";
import {
    trackAemUsedCompareClearAll,
    trackAemUsedCompareClose,
    trackAemUsedCompareOpen,
    trackAemUsedCompareRemoveVehicle,
} from "../utils/tracking";
import { CompareV2Mode } from "../../../shared-logic/features/compare/utils/types";
import { useDispatch } from "../../../shared-logic/redux/store";

type useUsedCarHeaderReturnType = {
    trackedToggleExpanded: () => void;
    trackedClearAll: () => void;
    trackedClearCar: (index: number, name: string) => void;
};

const useUsedCarHeader = (
    expanded: boolean,
    toggleExpanded: () => void,
    amountOfCars: number,
): useUsedCarHeaderReturnType => {
    const dispatch = useDispatch<CompareUSCDispatchType>();

    // ----------------------------------------------------------------------
    // Tracking
    // ----------------------------------------------------------------------
    const trackedToggleExpanded = (): void => {
        toggleExpanded();
        if (expanded) trackAemUsedCompareClose();
        else trackAemUsedCompareOpen(amountOfCars);
    };

    const trackedClearAll = (): void => {
        dispatch(usedCompareClearAll());
        trackAemUsedCompareClearAll();
    };

    const trackedClearCar = (index: number, name: string): void => {
        dispatch(showModal({ type: MODAL_DELETE_CAR, mode: CompareV2Mode.Used, indexToDelete: index }));
        trackAemUsedCompareRemoveVehicle(name);
    };

    return {
        trackedToggleExpanded,
        trackedClearAll,
        trackedClearCar,
    };
};

export default useUsedCarHeader;
