import type { CommonSettingsType } from "../../../common-deprecated/settings/fetchCommonSettings";
import type { DfPricingSourceCode } from "../../shared-logic/utils/dfConstants";
import type { UscVehicleUrlInfo } from "../../shared-logic/utils/uscUtils";
import type { UscCashPriceType } from "../../shared-logic/utils/uscPriceUtils";
import { getUscCashPriceFromVehicle } from "../../shared-logic/utils/uscPriceUtils";
import type { LoadedUsedCompareCarType } from "../../../common-deprecated/features/compare/utils/usedCarCompareTypes";
import { censorProperty } from "../../../common-deprecated/utils";
import { formatNumberIntl } from "../../../common-deprecated/settings/utils/commonSettingUtils";
import type { UscEcoType } from "../../shared-logic/utils/uscEcoUtils";
import { getUsedCarCompareEmissionValue } from "../../shared-logic/utils/uscEcoUtils";
import type { QuickSpecLabelType } from "../../shared-logic/utils/uscSpecUtils";
import { getUscManufacturerWarrantySpec, getUscVehiclePowerOutput } from "../../shared-logic/utils/uscSpecUtils";
import { getCommonLabel } from "../../../common-deprecated/utils/commonLabels";
import type { CompareUSCStateType } from "../redux/store";

export enum UsedCompareSpec {
    BodyType = "bodyType",
    Doors = "doors",
    Emission = "emission",
    Engine = "engine",
    ExteriorColour = "exteriorColour",
    FuelType = "fuelType",
    Mileage = "mileage",
    PowerOutput = "powerOutput",
    Transmission = "transmission",
    Warranty = "warranty",
    RegistrationDate = "registrationDate",
    Price = "price",
    WheelDrive = "wheelDrive",
    Distance = "distance",
    ModelYear = "modelYear",
    VinNumber = "vinNumber",
    DisplayAuthorization = "displayAuthorization",
    EngineNumber = "engineNumber",
    NextInspectionDate = "nextInspectionDate",
    IsPledgedSale = "isPledgedSale",
    EfficiencyClass = "efficiencyClass",
    ManufacturerWarranty = "manufacturerWarranty",
}

export type UsedParsedSpecEcoValueType = {
    letter: string;
    eco: UscEcoType;
};

export type UsedParsedSpecType = {
    values: {
        values: (string | number | UsedParsedSpecEcoValueType)[];
        unit?: string;
        price?: {
            cashPrice: UscCashPriceType;
            monthlyPrice: number;
            sourceCode?: DfPricingSourceCode;
        }[];
    };
    type: UsedCompareSpec;
};

export const getSpecValues = (
    keys: UsedCompareSpec[],
    cars: LoadedUsedCompareCarType[],
    settings: CommonSettingsType,
    quickSpecLabels: QuickSpecLabelType,
): UsedParsedSpecType[] => {
    // requested specs are picked from the car based on the order they were requested
    const specs: UsedParsedSpecType[] = keys
        // eslint-disable-next-line array-callback-return,consistent-return
        .map((type: UsedCompareSpec): UsedParsedSpecType => {
            switch (type) {
                case UsedCompareSpec.BodyType: {
                    return { values: { values: cars.map((car) => car.product.bodyType) }, type };
                }
                case UsedCompareSpec.Doors: {
                    return { values: { values: cars.map((car) => `${car.product.doors}`) }, type };
                }
                case UsedCompareSpec.Emission: {
                    return {
                        values: { values: cars.map((car) => getUsedCarCompareEmissionValue(car)?.value || "") },
                        type,
                    };
                }
                case UsedCompareSpec.Engine: {
                    return { values: { values: cars.map((car) => car.product.engine?.description || "") }, type };
                }
                case UsedCompareSpec.ExteriorColour: {
                    return { values: { values: cars.map((car) => car.exteriorColour) }, type };
                }
                case UsedCompareSpec.FuelType: {
                    return {
                        values: {
                            values: cars.map((car) => car.product.engine.displayFuelType || ""),
                        },
                        type,
                    };
                }
                case UsedCompareSpec.Mileage: {
                    return {
                        values: {
                            values: cars.map((car) =>
                                car?.mileage?.value ? formatNumberIntl(car.mileage.value, settings.culture.name) : "",
                            ),
                            unit: cars[0]?.mileage?.unit.description || "",
                        },
                        type,
                    };
                }
                case UsedCompareSpec.PowerOutput: {
                    return {
                        // Workaround due assumption that unit is always idententical and here we dynamically set it
                        values: {
                            values: cars.map((car) => {
                                const formattedPowerOutput = getUscVehiclePowerOutput(car.product.engine);

                                if (!formattedPowerOutput) {
                                    return "";
                                }

                                return `${formattedPowerOutput.value} ${formattedPowerOutput.unit.description}`;
                            }),
                            unit: "",
                        },
                        type,
                    };
                }
                case UsedCompareSpec.Transmission: {
                    return {
                        values: {
                            values: cars.map((car) => car.product.transmission.name || ""),
                        },
                        type,
                    };
                }
                case UsedCompareSpec.Warranty: {
                    return { values: { values: cars.map((car) => car.warranty?.description || "") }, type };
                }
                case UsedCompareSpec.RegistrationDate: {
                    return {
                        values: {
                            values: cars.map((car) => {
                                // If registrationDate is not available, we fallback to localRegistrationDate (OR-8028)
                                const registrationDate =
                                    car.history?.registrationDate || car.history?.localRegistrationDate;

                                return registrationDate ? `${new Date(registrationDate).getFullYear()}` : "";
                            }),
                        },
                        type,
                    };
                }
                case UsedCompareSpec.Price: {
                    return {
                        values: {
                            price: cars.map((car) => ({
                                cashPrice: getUscCashPriceFromVehicle(car),
                                monthlyPrice: car.monthlyPrice,
                                sourceCode: car.price.pricingSource?.code,
                            })),
                            values: [],
                        },
                        type,
                    };
                }
                case UsedCompareSpec.WheelDrive: {
                    return { values: { values: cars.map((car) => car.product.transmission.gearbox) }, type };
                }
                case UsedCompareSpec.Distance: {
                    return {
                        values: {
                            values: cars.map((car) => (car.dealer?.distance ? car.dealer.distance : "")),
                        },
                        type,
                    };
                }
                case UsedCompareSpec.ModelYear: {
                    return {
                        values: {
                            values: cars.map((car) => (car.product.modelYear ? car.product.modelYear : "")),
                        },
                        type,
                    };
                }
                case UsedCompareSpec.VinNumber: {
                    return {
                        values: {
                            values: cars.map((car) => (car.vin ? censorProperty(car.vin, 0, 6) : "")),
                        },
                        type,
                    };
                }
                case UsedCompareSpec.DisplayAuthorization: {
                    return {
                        values: {
                            values: cars.map((car) =>
                                car.dealer?.outletNmsc?.uclAuthorizationNumber
                                    ? car.dealer.outletNmsc.uclAuthorizationNumber
                                    : "",
                            ),
                        },
                        type,
                    };
                }
                case UsedCompareSpec.EngineNumber: {
                    return {
                        values: {
                            values: cars.map((car) =>
                                car.product.engine?.engineNumber
                                    ? censorProperty(car.product.engine.engineNumber, 0, 5)
                                    : "",
                            ),
                        },
                        type,
                    };
                }
                case UsedCompareSpec.NextInspectionDate: {
                    return {
                        values: {
                            values: cars.map((car) => (car.nextInspectionDate ? car.nextInspectionDate : "")),
                        },
                        type,
                    };
                }
                case UsedCompareSpec.IsPledgedSale: {
                    return {
                        values: {
                            values: cars.map((car) =>
                                car.isPledgedSale ? quickSpecLabels.yesLabel : quickSpecLabels.noLabel,
                            ),
                        },
                        type,
                    };
                }
                case UsedCompareSpec.EfficiencyClass: {
                    return {
                        values: {
                            values: cars.map((car) => {
                                const { eco } = car.product;
                                return eco?.efficiencyClass?.description
                                    ? { letter: eco.efficiencyClass.description, eco }
                                    : "";
                            }),
                        },
                        type,
                    };
                }
                case UsedCompareSpec.ManufacturerWarranty: {
                    return {
                        values: {
                            values: cars.map((car) => {
                                const { warranties, mileage } = car;

                                const spec = getUscManufacturerWarrantySpec(
                                    warranties,
                                    quickSpecLabels,
                                    settings.culture.name,
                                    mileage?.value,
                                );

                                return spec || "";
                            }),
                        },
                        type,
                    };
                }
            }
        })
        .filter((parsedSpecs: UsedParsedSpecType) => {
            // Filter specs which only have empty values.
            // Comment: The above comment is incorrect. When one value is not available this will be true.
            //          This means once value is not filled it, the spec will not be show at all.
            //          Is this intended?
            return parsedSpecs.values.values.every((value) => value !== "" && value !== undefined);
        });

    return specs;
};

export const getUscVehicleUrlInfoFromCompareResult = (vehicle: LoadedUsedCompareCarType): UscVehicleUrlInfo => ({
    model: vehicle.product.model,
    transmissionType: vehicle?.product.transmission.transmissionType?.description || "",
    bodyType: vehicle.product.bodyType,
    manufacturer: vehicle.product.brand.description,
    vehicleForSaleId: vehicle.id,
    marketingFuelType: vehicle.product.engine.marketingFuelType.description,
    firstRegistrationDate: vehicle.history?.registrationDate || "",
    productionDate: vehicle.productionDate,
});

export const compareQuickSpecLabelsSelector = ({ commonSettings }: CompareUSCStateType): QuickSpecLabelType => ({
    yesLabel: getCommonLabel({ commonSettings }, "uscSpecLabelYes"),
    noLabel: getCommonLabel({ commonSettings }, "uscSpecLabelNo"),
    kmLimitLabel: getCommonLabel({ commonSettings }, "uscManufacturerWarrantyKmLimit"),
    validUntilLabel: getCommonLabel({ commonSettings }, "uscManufacturerWarrantyValidUntil"),
    expiredLabel: getCommonLabel({ commonSettings }, "uscManufacturerWarrantyExpired"),
    noneLabel: getCommonLabel({ commonSettings }, "uscManufacturerWarrantyNone"),
    unspecifiedLabel: getCommonLabel({ commonSettings }, "uscManufacturerWarrantyUnspecified"),
});
