import type { SpecItemType } from "../../../common-deprecated/features/compare/utils/newCarCompareTypes";
import { useCompareUSCSelector } from "../redux/store";
import { CompareUscSpecSection } from "../utils/constants";

const aemSpecSectionCategoryMap: {
    [key in CompareUscSpecSection]: string;
} = {
    [CompareUscSpecSection.Dimensions]: "usedCompareCatDimension",
    [CompareUscSpecSection.Consumption]: "usedCompareCatConsumption",
    [CompareUscSpecSection.Engine]: "usedCompareCatEngine",
    [CompareUscSpecSection.Performance]: "usedCompareCatPerformance",
    [CompareUscSpecSection.Transmission]: "usedCompareCatTransmission",
};

const useEnabledSpecSections = (): SpecItemType[] => {
    const parsedSpecs = useCompareUSCSelector((state) => state.usedCarCompare.parsedSpecs);
    const enabledSpecSections = useCompareUSCSelector((state) => state.compareUscSettings.enabledSpecSections);
    const enabledSpecCategories = enabledSpecSections.map(
        (enabledSpecSection) => aemSpecSectionCategoryMap[enabledSpecSection],
    );

    return parsedSpecs.filter(({ category }) => enabledSpecCategories.includes(category));
};

export default useEnabledSpecSections;
