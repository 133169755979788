import type { CommonSettingsType } from "../../../../common-deprecated/settings/fetchCommonSettings";
import type { SpecItemType } from "../../../../common-deprecated/features/compare/utils/newCarCompareTypes";
import type { UsedParsedEquipment, EquipmentSpecNameIdValueType } from "../compareTypes";
import { UsedSpecCategoryCode } from "../compareTypes";
import type { UsedCompareCarType } from "../../../../common-deprecated/features/compare/utils/usedCarCompareTypes";
import { getUsedCarCompareEquipmentCategories } from "../../../shared-logic/utils/uscUtils";
import {
    getUsedCarCompareEmissionValue,
    getUsedCarCompareConsumptionValue,
} from "../../../shared-logic/utils/uscEcoUtils";
import { getUscVehiclePowerOutput } from "../../../shared-logic/utils/uscSpecUtils";

export const parseUsedCarSpecs = (
    cars: UsedCompareCarType[],
    culture: CommonSettingsType["culture"],
): SpecItemType[] => {
    /**
     * For now the categories and which values go into them are hardcoded because of no other alternative.
     * These are the same categories and specs as the product detail page
     */
    const baseSpec = {
        id: "",
        code: "",
        index: 0,
        values: [] as EquipmentSpecNameIdValueType[],
    };

    const doorSpecs = { ...baseSpec, name: "usedCompareSpecDoors" };
    const seatsSpecs = { ...baseSpec, name: "usedCompareSpecSeats" };
    const totalCapacitySpecs = { ...baseSpec, name: "usedCompareSpecCarVolume" }; // value -> bootCapacity
    const consumptionSpecs = { ...baseSpec, name: "usedCompareSpecConsumption" };
    const efficiencyClassSpecs = { ...baseSpec, name: "usedCompareSpecEfficiencyClass" };
    const fuelEconomySpecs = { ...baseSpec, name: "usedCompareSpecFuelEconomy" };
    const emissionSpecs = { ...baseSpec, name: "usedCompareSpecEmission" };
    const cylinderSpecs = { ...baseSpec, name: "usedCompareSpecCylinders" };
    const capacitySpecs = { ...baseSpec, name: "usedCompareSpecEngineCapacity" }; // value -> displacement
    const maxSpeedSpecs = { ...baseSpec, name: "usedCompareSpecMaxSpeed" };
    const accelerationSpecs = { ...baseSpec, name: "usedCompareSpecAcceleration" };
    const wheelDriveSpecs = { ...baseSpec, name: "usedCompareSpecWheelDrive" }; // value -> transmission.gearbox
    const transmissionSpecs = { ...baseSpec, name: "usedCompareSpecTransmission" }; // value -> transmission.transmissionType
    const powerSpecs = { ...baseSpec, name: "usedCompareSpecPowerOutput" };
    const fiscalPowerSpecs = { ...baseSpec, name: "usedCompareSpecFiscalePower" }; // value -> fiscalPower

    cars.forEach((car) => {
        if (car.loading) return "";
        doorSpecs.values = [...doorSpecs.values, { value: car.product.doors }];
        seatsSpecs.values = [...seatsSpecs.values, { value: car.product.seats }];
        totalCapacitySpecs.values = [
            ...totalCapacitySpecs.values,
            {
                value: car.product.bootCapacity?.value
                    ? `${car.product.bootCapacity?.value} ${car.product.bootCapacity.unit.description}`
                    : "",
            },
        ];
        const consumption = getUsedCarCompareConsumptionValue(car);
        consumptionSpecs.values = [
            ...consumptionSpecs.values,
            {
                value: consumption ? `${consumption.value} ${consumption.unit}` : "",
            },
        ];
        efficiencyClassSpecs.values = [
            ...efficiencyClassSpecs.values,
            {
                value: car.product.eco?.efficiencyClass?.description || "",
                ...{ eco: car.product.eco || undefined },
            },
        ];
        fuelEconomySpecs.values = [...fuelEconomySpecs.values, { value: car.product.eco?.label?.description || "" }];
        const emission = getUsedCarCompareEmissionValue(car);
        emissionSpecs.values = [
            ...emissionSpecs.values,
            {
                value: emission ? `${emission.value} ${emission.unit}` : "",
            },
        ];
        cylinderSpecs.values = [...cylinderSpecs.values, { value: car.product.engine.cylinders || "" }];
        capacitySpecs.values = [
            ...capacitySpecs.values,
            {
                value: car.product.engine.displacement?.value
                    ? `${car.product.engine.displacement.value} ${car.product.engine.displacement.unit.description}`
                    : "",
            },
        ];
        maxSpeedSpecs.values = [
            ...maxSpeedSpecs.values,
            {
                value: car.product.performance?.maxSpeed?.value
                    ? `${car.product.performance.maxSpeed.value} ${car.product.performance.maxSpeed?.unit.description}`
                    : "",
            },
        ];
        accelerationSpecs.values = [
            ...accelerationSpecs.values,
            {
                value: car.product.performance?.acceleration?.value
                    ? `${new Intl.NumberFormat(culture?.name, {
                          minimumFractionDigits: 1,
                          maximumFractionDigits: 1,
                      }).format(car.product.performance.acceleration.value)} ${
                          car.product.performance.acceleration.unit.description
                      }`
                    : "",
            },
        ];
        wheelDriveSpecs.values = [...wheelDriveSpecs.values, { value: car.product.transmission.gearbox || "" }];
        transmissionSpecs.values = [
            ...transmissionSpecs.values,
            { value: car.product.transmission.transmissionType?.description || "" },
        ];
        const vehiclePowerOutput = getUscVehiclePowerOutput(car.product.engine);
        powerSpecs.values = [
            ...powerSpecs.values,
            {
                value: vehiclePowerOutput ? `${vehiclePowerOutput.value} ${vehiclePowerOutput.unit.description}` : "",
            },
        ];
        fiscalPowerSpecs.values = [
            ...fiscalPowerSpecs.values,
            {
                value: car.product.engine.fiscalPower?.value
                    ? `${car.product.engine.fiscalPower.value} ${car.product.engine.fiscalPower.unit.description}`
                    : "",
            },
        ];
    });

    return [
        {
            category: "usedCompareCatDimension",
            categoryCode: UsedSpecCategoryCode.Dimension,
            specs: [doorSpecs, seatsSpecs, totalCapacitySpecs],
        },
        {
            category: "usedCompareCatConsumption",
            categoryCode: UsedSpecCategoryCode.Consumption,
            specs: [consumptionSpecs, fuelEconomySpecs, emissionSpecs, efficiencyClassSpecs],
        },
        {
            category: "usedCompareCatEngine",
            categoryCode: UsedSpecCategoryCode.Engine,
            specs: [cylinderSpecs, capacitySpecs, powerSpecs, fiscalPowerSpecs],
        },
        {
            category: "usedCompareCatPerformance",
            categoryCode: UsedSpecCategoryCode.Performance,
            specs: [maxSpeedSpecs, accelerationSpecs],
        },
        {
            category: "usedCompareCatTransmission",
            categoryCode: UsedSpecCategoryCode.Transmission,
            specs: [wheelDriveSpecs, transmissionSpecs],
        },
    ];
};

export const parseUsedCarEquipment = (cars: UsedCompareCarType[]): UsedParsedEquipment[] => {
    const categoryArray = [] as UsedParsedEquipment[];
    // loop to get all the shared equipmentCategories and its items.
    cars.forEach((car) => {
        if (car.loading) return "";
        getUsedCarCompareEquipmentCategories(car).forEach((category) => {
            // see if another car hasn't had this category yet so it doesn't get added twice.
            const existingCat = categoryArray.find((x) => x.code === category.code);

            if (existingCat) {
                // if the category already exists. just add the new items to it instead of making a new category
                category.items.forEach((item) => {
                    if (!existingCat.items.find((i) => i.code === item.code)) {
                        existingCat.items = [...existingCat.items, { ...item, id: item.code, index: 0 }];
                    }
                });
            } else {
                // Parse the category instance so it won't be linked to the cars redux store anymore.
                const newCat = {
                    ...category,
                    items: category.items.map((item) => ({ ...item, id: item.code, index: 0 })),
                };
                // if the category doesn't exist yet push the new category to the categoryArray
                categoryArray.push(newCat);
            }
        });
    });

    // Loop to get the values for all the items for all the cars.
    categoryArray.forEach((category) => {
        cars.forEach((car) => {
            if (car.loading) return "";
            //loop through all the items in the equipment category
            category.items.forEach((item) => {
                // check if the car has the item by looking at its item code
                if (
                    getUsedCarCompareEquipmentCategories(car)
                        .find((c) => c.code === category.code)
                        ?.items.find((i) => i.code === item.code)
                ) {
                    // If the car has the item put value to "s"
                    item.values = item.values
                        ? [...item.values, { value: "s", colours: [] }]
                        : [{ value: "s", colours: [] }];
                } else {
                    // If the car does not have the item put value to ""
                    item.values = item.values
                        ? [...item.values, { value: "", colours: [] }]
                        : [{ value: "", colours: [] }];
                }
            });
        });
    });

    return categoryArray;
};
