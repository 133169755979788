import type { CompareV2Mode } from "./types";
import type { UsedCompareCarType } from "../../../../common-deprecated/features/compare/utils/usedCarCompareTypes";
import type {
    ContactDealerType,
    MODAL_USC_FORM,
} from "../../../../used-stock-cars/shared-logic/utils/integrated-modal/integratedModalConstants";
import type { ModelGradeBodyTypeIdsType } from "../../../../common-deprecated/features/compare/utils/newCarCompareTypes";

export const MODAL_DELETE_CAR = "deleteCar" as const;
export const MODAL_REPLACE_CAR = "replaceCar" as const;
export const MODAL_SELECT_ENGINE = "selectEngine" as const;

export type ReplaceCarType = {
    type: typeof MODAL_REPLACE_CAR;
    newCarToAdd?: ModelGradeBodyTypeIdsType;
    usedCarToAdd?: UsedCompareCarType;
};

export type DeleteCarType = {
    type: typeof MODAL_DELETE_CAR;
    mode: CompareV2Mode;
    indexToDelete: number;
};

export type SelectEngineType = {
    type: typeof MODAL_SELECT_ENGINE;
    index: number;
};

export type CompareV2ModalIdsType =
    | typeof MODAL_USC_FORM
    | typeof MODAL_DELETE_CAR
    | typeof MODAL_REPLACE_CAR
    | typeof MODAL_SELECT_ENGINE;

export type CompareV2ModalsType = ContactDealerType | DeleteCarType | ReplaceCarType | SelectEngineType;
